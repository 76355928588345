/* Sidebar container */
.item-code-manager {
  position: fixed;
  top: 0;
  left: 1090px;
  width: 450px;
  height: 100%; /* Sidebar stays fixed at full height */
  background-color: #f9f9f9;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  flex-direction: column; /* Align children vertically */
}

/* Title styles */
.item-code-manager h1 {
  font-size: 18px;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

/* Dropdown and button styles */
.item-code-manager label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.item-code-manager select {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.item-code-manager button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 14px;
}

.item-code-manager button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Make only the table container scrollable */
.item-code-manager .item-code-table {
  flex-grow: 1; /* Use remaining space */
  overflow-y: auto; /* Enable scrolling for the table */
  border: 1px solid #ccc;
  margin-top: 20px;
  max-height: calc(100% - 250px); /* Ensure space for other elements */
}

/* Table styles */
.item-code-manager table {
  width: 100%;
  border-collapse: collapse;
}

.item-code-manager th,
.item-code-manager td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.item-code-manager th {
  background-color: #707070;
  font-weight: bold;
}

/* Input field for quantities */
.item-code-manager input[type='text'] {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .item-code-manager {
    width: 100%;
    height: auto;
    position: static;
    box-shadow: none;
  }

  .item-code-manager .item-code-table {
    max-height: 400px; /* Adjust for smaller screens */
  }
}
