.bill-detail {
    font-family: Arial, sans-serif;
    margin: 20px;
    margin-top: -10px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.bill-detail h3 {
    font-size: 22px;
    margin-top: -5px;
    margin-bottom: 20px;
}

.bill-detail label {
    font-weight: bold;
}

.bill-detail input {
    padding: 8px;
    margin-left: 5px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 200px;
}

.bill-detail button {
    margin-left: 10px;
    padding: 8px 16px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}


.bill-detail-defet-btn{
    background-color: rgb(245, 49, 49) !important;
   }

.bill-detail-exchange-btn{
    margin-top: 10px;
}

.bill-detail-delete-btn{
    margin-top: 5px;
    background-color: rgb(245, 49, 49) !important;
}


.bill-detail button:hover {
    background-color: #0056b3;
}

.bill-detail table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    table-layout: fixed;
    overflow: hidden;
    /* display: block; */
}

.bill-detail th,
.bill-detail td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    word-wrap: break-word;
}

.bill-detail th {
    background-color: #707070;
    color: white;
}

.bill-detail-table tr:nth-child(even) {
    background-color: #cbcbcb;
}

.bill-detail tbody {
    display: block;
    max-height: 300px; /* Adjust the height as needed */
    overflow-y: auto; /* Vertical scroll */
    overflow-x: hidden; /* Prevent horizontal scroll */
}

.bill-detail thead {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.bill-detail tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.bill-detail-wrapper {
    overflow-x: auto; /* Horizontal scroll */
}

.bill-detail th:nth-child(1),
.bill-detail td:nth-child(1) {
    text-align: center; /* Horizontally center the content */
    width: 50px; /* Serial No */
}

.bill-detail th:nth-child(2),
.bill-detail td:nth-child(2) {
    text-align: center; /* Horizontally center the content */   
    width: 110px; /* Item Barcode ID */
}

.bill-detail th:nth-child(3),
.bill-detail td:nth-child(3) {
    text-align: center;
    width: 200px; /* Description */
}


.bill-detail th:nth-child(4),
.bill-detail td:nth-child(4) {
    text-align: center;
    width: 100px; /* Item Type */
}

.bill-detail th:nth-child(5),
.bill-detail td:nth-child(5) {
    text-align: center;
    width: 100px; /* Item Color */
}

.bill-detail th:nth-child(6),
.bill-detail td:nth-child(6) {
    text-align: center; /* Horizontally center the content */
    width: 50px; /* Item Size */
    
    
}

.bill-detail th:nth-child(7),
.bill-detail td:nth-child(7) {
    text-align: center;
    width: 120px; /* Item Category */
    
    
}

.bill-detail th:nth-child(8),
.bill-detail td:nth-child(8) {
    text-align: center; /* Horizontally center the content */
    width: 60px; /* Quantity */
}

.bill-detail th:nth-child(9),
.bill-detail td:nth-child(9) {
    text-align: center; /* Horizontally center the content */
    width: 70px; /* Sell Price */
}

.bill-detail th:nth-child(10),
.bill-detail td:nth-child(10) {
    text-align: center; /* Horizontally center the content */
    width: 80px; /* Total Amount */
}

.bill-detail th:nth-child(11),
.bill-detail td:nth-child(11) {
    text-align: center;
    width: 100px; /* Action */
}

.bill-detail th:nth-child(12),
.bill-detail td:nth-child(12) {
    text-align: center;
    width: 100px; 
}
#exchange-btn {
    background-color: #007bff;
}

.bill-detail td button {
    background-color: #00cef7;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    margin: 3px;
    width: 100px;
}

.bill-detail td button:hover {
    background-color: #2205b6;
}


.bill-detail-return-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.bill-detail-return-modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 1000px !important;
}

.bill-detail-return-items-table {
    width: 100%;
    border-collapse: collapse;
}

.bill-detail-return-items-table th, .bill-detail-return-items-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.bill-detail-return-items-table th {
    /* background-color: #f2f2f2; */
}

.bill-detail-return-total-amount {
    text-align: right;
    margin-top: 10px;
}

.bill-detail-return-modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

/* Popup Styles */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}



/* Table Styles */
.bill-detail-table th,
.bill-detail-table td {
    padding: 8px;
    text-align: left;
}

/* Return Modal */
.bill-detail-return-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.bill-detail-return-modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 800px;
    overflow-x: auto;
}

.bill-detail-return-items-table th,
.bill-detail-return-items-table td {
    padding: 8px;
    text-align: left;
}

/* Popup Styles */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.popup-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    position: relative;
}

.popup.success .popup-content::before {
    content: '✔️'; /* Unicode for green tick */
    color: green;
    font-size: 24px;
    display: block;
    margin-bottom: 10px;
}

.popup.error .popup-content::before {
    content: '❌'; /* Unicode for red cross */
    color: red;
    font-size: 24px;
    display: block;
    margin-bottom: 10px;
}

.popup-content p {
    margin: 0;
    font-size: 16px;
}

.popup-content button {
    margin-top: 10px;
}


.bill-detail-info {
    margin-top: -5px !important;
}

.billdets-info p{
    margin: 5px;
}




/* Modal container */
.bill-detail-exchange-modal {
    position: fixed; /* Position the modal relative to the viewport */
    z-index: 1000; /* Ensure the modal is on top of other elements */
    top: 0;
    left: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    display: flex; /* Use flexbox to center the modal content */
    align-items: center; /* Vertically center */
    justify-content: center; /* Horizontally center */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

/* Modal content */
.bill-detail-exchange-modal-content {
    background-color: #fff; /* White background */
    padding: 20px; /* Some padding */
    border-radius: 8px; /* Rounded corners */
    max-width: 600px; /* Maximum width */
    width: 90%; /* Responsive width */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Subtle shadow */
}

/* Modal header */
.bill-detail-exchange-modal-content h2 {
    margin-top: 0; /* Remove default margin */
    margin-bottom: 20px; /* Add some space below the heading */
}

/* Table styles */
.bill-detail-exchange-items-table {
    width: 100%; /* Full width */
    border-collapse: collapse; /* Collapse borders */
    margin-bottom: 20px; /* Space below the table */
}

.bill-detail-exchange-items-table th,
.bill-detail-exchange-items-table td {
    border: 1px solid #ddd; /* Border around cells */
    padding: 8px; /* Cell padding */
    text-align: left; /* Left-align text */
}

.bill-detail-exchange-items-table th {
    background-color: #f2f2f2; /* Light gray background for headers */
}

/* Input styles */
.bill-detail-exchange-items-table input[type="number"] {
    width: 60px; /* Set a fixed width */
    padding: 4px; /* Some padding */
    box-sizing: border-box; /* Ensure padding doesn't affect width */
}

/* Buttons container */
.bill-detail-exchange-modal-content div {
    display: flex;
    justify-content: flex-end; /* Align buttons to the right */
    gap: 10px; /* Space between buttons */
}

/* Buttons styles */
.bill-detail-exchange-modal-content button {
    padding: 10px 20px; /* Padding */
    border: none; /* Remove borders */
    border-radius: 4px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
}

.bill-detail-exchange-modal-content button:first-child {
    background-color: #28a745; /* Green background */
    color: #fff; /* White text */
}

.bill-detail-exchange-modal-content button:last-child {
    background-color: #dc3545; /* Red background */
    color: #fff; /* White text */
}


/* Modal container */
.unique-defect-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensure it appears on top of other content */
    overflow: auto; /* Allow scrolling if content overflows */
}

/* Modal content */
.unique-defect-modal-content {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    max-width: 850px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    margin: 0 auto; /* Center the content horizontally */
}

/* Modal heading */
.unique-defect-modal-content h2 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 1.5em;
}

/* Table styles */
.unique-defect-items-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.unique-defect-items-table th, 
.unique-defect-items-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.unique-defect-items-table th {
    background-color: #707070;
    font-weight: bold;
}

.unique-defect-items-table td input {
    width: 100%;
    padding: 4px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Button container styles */
.unique-defect-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.unique-defect-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
}

.unique-defect-buttons button:first-of-type {
    background-color: #4CAF50; /* Green background for Confirm */
    color: #fff;
}

.unique-defect-buttons button:last-of-type {
    background-color: #f44336; /* Red background for Cancel */
    color: #fff;
}

.unique-defect-buttons button:hover {
    opacity: 0.9;
}


/* Modal container */
.item-exchange-modal-bill {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    width: 400px;
    background-color: white;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    width:100%;
    height: 100%;
}

/* Modal content */
.item-exchange-modal-bill h2 {
    font-size: 20px;
    margin-bottom: 15px;
    text-align: center;
}

.item-exchange-modal-bill p {
    font-size: 16px;
    margin: 10px 0;
}

/* Button styles */
.item-exchange-modal-bill button {
    display: block;
    margin: 10px auto;
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.item-exchange-modal-bill button:hover {
    background-color: #0056b3;
}

/* Close button */
.item-exchange-modal-bill .close-button {
    background-color: #dc3545;
}

.item-exchange-modal-bill .close-button:hover {
    background-color: #c82333;
}

/* Optional: overlay for dim background effect */
.item-exchange-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}


