/* Salary.css */

.salary-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.menu-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.menu-bar button {
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 16px;
  border: none;
  background-color: transparent;
  color: #000000;
  /* Set default text color to black */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.menu-bar button.active {
  background-color: #007bff;
  color: #000000;
  /* Ensure text color is black when active */
  border-radius: 4px;
}

.menu-bar button:hover {
  background-color: #5092d3;
  color: #000000;
  /* Ensure text color is black on hover */
}