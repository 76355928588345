/* Overlay for dimming the background */
.inventory-update-history-popup__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

/* Popup container */
.inventory-update-history-popup__content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 600px;
    background: white;
    padding: 20px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    z-index: 1000;
    overflow-y: auto;
    max-height: 80%;
}

/* Close button */
.inventory-update-history__close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #666;
}

/* Title styling */
.inventory-update-history__title {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: center;
}

/* Table container */
.inventory-update-history__table-container {
    max-height: 60vh;
    overflow-y: auto;
    margin-top: 20px;
}

/* Table styling */
.inventory-update-history__table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    text-align: left;
}

.inventory-update-history__table th,
.inventory-update-history__table td {
    padding: 8px;
    border: 1px solid #ddd;
}

.inventory-update-history__table th {
    background-color: #707070;
    color: white;
    font-weight: bold;
}

/* Sub-table for expanded rows */
.inventory-update-history__sub-entries {
    background-color: #f9f9f9;
}

.inventory-update-history__sub-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 13px;
    margin-top: 10px;
}

.inventory-update-history__sub-table th,
.inventory-update-history__sub-table td {
    padding: 5px;
    border: 1px solid #ccc;
}
