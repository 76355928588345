/* Keep existing styles */
.sales-school-report {
    padding: 20px;
    margin-top: -15px;
    font-family: Arial, sans-serif;
}

.sales-report-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.sales-date-inputs {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.sales-input-date {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.sales-fetch-btn {
    padding: 8px 16px;
    font-size: 14px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.sales-fetch-btn:hover {
    background-color: #0056b3;
}

.sales-loading-message,
.sales-error-message,
.sales-no-data-message {
    margin-top: 20px;
    font-size: 16px;
    color: #666;
}

.sales-error-message {
    color: red;
}



.sales-report-table-wrapper {
    max-height: 420px;
    margin-top: -10px;
    overflow-y: auto;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 20px;
}

.sales-report-table {
    width: 100%;
    border-collapse: collapse;
}

.sales-report-table th,
.sales-report-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
}

.sales-report-table th {
    background-color: #707070;
    color: white;
    font-weight: bold;
    position: sticky;
    top: 0;
    z-index: 10;
}

.sales-view-btn {
    padding: 6px 12px;
    font-size: 12px;
    color: #fff;
    background-color: #28a745;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.sales-view-btn:hover {
    background-color: #218838;
}

/* Improved popup styles */
.sales-school-report-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.sales-school-report-popup-content {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-height: 90vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.sales-school-report-popup-content h3 {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 20px;
    color: #333;
    text-align: center;
}

/* Create a scrollable container for the detail table */
.sales-detail-table-container {
    overflow-x: auto;
    overflow-y: auto;
    max-height: 1000px;
    margin-bottom: 15px;
}

.sales-detail-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
    min-width: 100%;
}

.sales-detail-table th,
.sales-detail-table td {
    padding: 10px 8px;
    border: 1px solid #ddd;
    white-space: nowrap;
    font-size: 14px;
}

.sales-detail-table th {
    background-color: #707070;
    color: white;
    font-weight: bold;
    text-align: left;
    position: sticky;
    top: 0;
    z-index: 5;
}

.sales-detail-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.sales-detail-table tr:hover {
    background-color: #f1f7ff;
}

.sales-school-report-popup-close-btn {
    background-color: #d9534f;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    align-self: center;
    font-weight: bold;
}

.sales-school-report-popup-close-btn:hover {
    background-color: #c9302c;
}

/* Total sales footer */
.school-total-sale {
    text-align: center;
    margin: 15px 0;
    font-weight: bold;
}

/* Export button styling */
button {
    padding: 8px 16px;
    font-size: 14px;
    color: #fff;
    background-color: #17a2b8;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 5px;
}

button:hover {
    background-color: #138496;
}