/* General container styles */
.edit-stock-container {
  padding: 20px;
  margin-top: -15px;
  background-color: #f9fafb;
  border-radius: 8px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  transition: padding 0.3s ease;
  width: 100%;
  box-sizing: border-box;
}

/* Controls area styling */
.edit-stock-container-controls {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.edit-stock-container-submit-btn {
  padding: 10px 20px;
  background-color: #0d6efd;
  color: white;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  min-width: 150px;
}

.edit-stock-container-submit-btn:hover {
  background-color: #0b5ed7;
}

.edit-stock-container-submit-btn:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

/* Search bar styling */
.edit-stock-container-search-bar-wrapper {
  display: flex;
  gap: 15px;
  margin: 20px 0;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.edit-stock-container-search-bar,
.edit-stock-container-quantity-filter {
  padding: 10px;
  margin-top: -15px;
  border: 1px solid #000;
  border-radius: 4px;
  flex-grow: 1;
  height: 10px;
  max-width: 100px;
  font-size: 14px;
  transition: border-color 0.2s;
}

.edit-stock-container-search-bar:focus,
.edit-stock-container-quantity-filter:focus {
  outline: none;
  border-color: #0d6efd;
  box-shadow: 0 0 0 2px rgba(13, 110, 253, 0.25);
}

/* Table wrapper - improved responsiveness */
.edit-stock-container-table-wrapper {
  max-height: 300px;
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;
  border: 1px solid #000;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

/* Table styling */
.edit-stock-container-table-wrapper table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.edit-stock-container-table-wrapper thead {
  background-color: #707070;
  color: white;
  position: sticky;
  top: 0;
  z-index: 10;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
}

.edit-stock-container-table-wrapper th {
  padding: 12px 6px;
  border: 0.5px solid #d3d0d0;
  text-align: left;
  font-weight: 600;
  color: #ffffff;
  font-size: 14px;
  white-space: normal;
  overflow-wrap: break-word;
  word-wrap: break-word;
  min-width: 60px;
}

.edit-stock-container-table-wrapper td {
  padding: 0;
  border: 0.5px solid #d3d0d0;
  vertical-align: middle;
  white-space: normal;
  overflow: hidden;
  height: 100%;
}

/* Column width guidelines */
.edit-stock-container-table-wrapper th:nth-child(1),
.edit-stock-container-table-wrapper td:nth-child(1) {
  width: 5%;
  min-width: 50px;
  text-align: center;
}

.edit-stock-container-table-wrapper th:nth-child(2),
.edit-stock-container-table-wrapper td:nth-child(2) {
  width: 8%;
  min-width: 80px;
}

.edit-stock-container-table-wrapper th:nth-child(3),
.edit-stock-container-table-wrapper td:nth-child(3) {
  width: 12%;
  min-width: 120px;
}

.edit-stock-container-table-wrapper th:nth-child(4),
.edit-stock-container-table-wrapper td:nth-child(4) {
  width: 8%;
  min-width: 80px;
}

.edit-stock-container-table-wrapper th:nth-child(5),
.edit-stock-container-table-wrapper td:nth-child(5) {
  width: 8%;
  min-width: 80px;
}

.edit-stock-container-table-wrapper th:nth-child(6),
.edit-stock-container-table-wrapper td:nth-child(6) {
  width: 6%;
  min-width: 60px;
}

.edit-stock-container-table-wrapper th:nth-child(7),
.edit-stock-container-table-wrapper td:nth-child(7) {
  width: 10%;
  min-width: 100px;
}

.edit-stock-container-table-wrapper th:nth-child(8),
.edit-stock-container-table-wrapper td:nth-child(8) {
  width: 7%;
  min-width: 70px;
}

.edit-stock-container-table-wrapper th:nth-child(9),
.edit-stock-container-table-wrapper td:nth-child(9) {
  width: 8%;
  min-width: 80px;
}

.edit-stock-container-table-wrapper th:nth-child(10),
.edit-stock-container-table-wrapper td:nth-child(10) {
  width: 8%;
  min-width: 80px;
}

.edit-stock-container-table-wrapper th:nth-child(11),
.edit-stock-container-table-wrapper td:nth-child(11) {
  width: 7%;
  min-width: 70px;
}

.edit-stock-container-table-wrapper th:nth-child(12),
.edit-stock-container-table-wrapper td:nth-child(12) {
  width: 7%;
  min-width: 70px;
  text-align: center;
}

/* Input styling - CRITICAL CHANGES HERE */
.edit-stock-container-input {
  width: 100% !important;
  height: 100% !important;
  padding: 8px 4px;
  border: none;
  border-radius: 0;
  font-size: 13px;
  background-color: #fff;
  transition: background-color 0.2s;
  box-sizing: border-box;
  margin: 0;
  display: block;
  text-overflow: ellipsis;
  min-height: 36px;
}

.edit-stock-container-input:focus {
  outline: none;
  background-color: #f0f7ff;
  box-shadow: none;
}

.edit-stock-container-input:hover:not(:focus) {
  background-color: #f8f9fa;
}

/* Specific styling for different input types */
.edit-stock-container-input[type="number"] {
  text-align: center;
  -moz-appearance: textfield;
}

.edit-stock-container-input[type="number"]::-webkit-inner-spin-button,
.edit-stock-container-input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Delete button */
.edit-stock-container-delete-btn {
  padding: 8px;
  background-color: #dc3545;
  color: white;
  border: none;
  max-width: 90px;
  max-height: 30px;
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;
  transition: background-color 0.2s;
  border-radius: 15px; /* Makes the button rounded */
}


.edit-stock-container-delete-btn:hover {
  background-color: #c82333;
}

/* Updating animation */
.edit-stock-container-updating-animation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  min-height: 200px;
}

.edit-stock-container-updating-animation p {
  margin-bottom: 15px;
  color: #000;
  font-weight: 500;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #0d6efd;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Alternating row colors */
.edit-stock-container-table-wrapper tbody tr:nth-child(even) {
  background-color: #f8f9fa;
}

.edit-stock-container-table-wrapper tbody tr:hover {
  background-color: #e9ecef;
}

/* No data found styling */
.edit-stock-container-table-wrapper p {
  padding: 20px;
  text-align: center;
  color: #000;
}

/* Responsive adjustments */
@media (max-width: 1500px) {
  .edit-stock-container {
    padding: 15px 10px;
  }
}

/* Medium screens */
@media (max-width: 1200px) {
  .edit-stock-container-table-wrapper {
    max-width: 100%;
    overflow-x: auto;
  }
  
  .edit-stock-container-table-wrapper table {
    min-width: 800px;
  }
  
  .edit-stock-container-search-bar,
  .edit-stock-container-quantity-filter {
    max-width: 100%;
  }
}

/* Small screens */
@media (max-width: 768px) {
  .edit-stock-container {
    padding: 10px 5px;
  }
  
  .edit-stock-container-search-bar-wrapper {
    flex-direction: column;
    gap: 10px;
  }
  
  .edit-stock-container-table-wrapper table {
    min-width: 600px;
  }
}

/* Very small screens */
@media (max-width: 480px) {
  .edit-stock-container-table-wrapper table {
    min-width: 450px;
  }
  
  .edit-stock-container {
    padding: 5px 2px;
  }
}