/* Main container */
.customer-due-list {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .customer-due-list h1 {
    margin-bottom: 20px;
    text-align: center;
    color: #333;
  }
  
  /* Error message */
  .customer-due-list-error {
    color: red;
    font-size: 14px;
    margin-bottom: 10px;
    text-align: center;
  }
  
  /* Filters section */
  .customer-due-list-filters {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    justify-content: center;
  }
  
  .customer-due-list-filter-input {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 200px;
  }
  
  /* Table container */
  .customer-due-list-table-container {
    max-height: 400px; /* Fixed height */
    overflow-y: auto; /* Enable vertical scrolling */
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Table styling */
  .customer-due-list-table {
    width: 100%;
  border-collapse: collapse;
  table-layout: fixed; /* Ensures that the table columns have fixed widths */
  }

  .customer-due-list-table thead {
    background-color: #707070;
    color: white;
  }

  .customer-due-list-table tr:nth-child(even) {
    background-color: #cbcbcb;
  }
  
  .customer-due-list-table th, .customer-due-list-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid black;
    word-wrap: break-word; /* Ensures long content wraps within the cell */
  }

  
  
  
  .customer-due-list-table tr:hover {
    background-color: #f9f9f9;
  }
  
  /* Payment dropdown */
  .customer-due-list-payment-mode {
    padding: 6px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Action buttons */
  .customer-due-list-action-buttons button {
    padding: 6px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .customer-due-list-action-buttons button:hover {
    background-color: #0056b3;
  }
  