/* Main container with renamed class */
.dashboard-container-fluid {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/* Top bar styles with renamed class */
.dashboard-top-bar {
  background-color: #0b9aed;
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

.dashboard-brand-heading {
  font-size: 1.5rem;
  font-weight: bold;
}

.dashboard-user-info {
  display: flex;
  align-items: center;
  gap: 15px;
}

.dashboard-logout-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1.5rem;
}

.dashboard-backup-button {
  background-color: orange;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px; /* Space between icon and text */
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.dashboard-backup-button:hover {
  background-color: darkorange;
  transform: scale(1.05);
}

.dashboard-backup-button:active {
  background-color: #cc7000;
  transform: scale(0.98);
}

.backup-icon {
  font-size: 20px; /* Adjust icon size */
}


.dashboard-refresh-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 0.8rem;
}

.dashboard-menu-toggle {
  background: none;
  border: none;
  font-size: 18px;
  margin: 0 5px;
  padding: 10px;
  cursor: pointer;
}

/* Sidebar styles with renamed class */
.dashboard-sidebar {
  position: fixed;
  top: 80px;
  left: -250px;
  width: 200px;
  height: calc(100% - 90px);
  background-color: #f8f9fa;
  padding: 10px;
  z-index: 2;
  overflow-y: auto;
  transition: left 0.3s ease-in-out;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.dashboard-sidebar.open {
  left: 0;
}

.dashboard-main-content-wrapper {
  display: flex;
  flex-grow: 1;
  transition: margin-left 0.3s ease-in-out;
}

.dashboard-main-content-wrapper.shifted {
  margin-left: 200px;
}

.dashboard-main-content {
  flex-grow: 1;
  padding: 20px;
}

/* List group with renamed class */
.dashboard-list-group {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

/* Updated sidebar button styles */
.dashboard-list-group-item {
  font-size: 15px;
  margin-bottom: 10px;
  cursor: pointer;
  
  background-color: #0b9aed;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 12px 15px;
  height: 40px;
  width: 100%;
  text-align: center;
  transition: background-color 0.3s, transform 0.2s;
}

.dashboard-list-group-item:hover {
  background-color: #0880c5;
  transform: translateY(-2px);
}

.dashboard-list-group-item:active {
  transform: translateY(1px);
}

.dashboard-cash-collection {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

/* Mobile Specific Styles */
@media (max-width: 768px) {
  .dashboard-brand-heading {
    font-size: 1rem;
  }

  .dashboard-user-info {
    flex-direction: column;
    align-items: flex-start;
    font-size: 12px;
  }

  .dashboard-refresh-button {
    margin-left: 5px;
  }

  .dashboard-list-group-item {
    font-size: 14px;
    padding: 10px;
    height: 45px;
  }

  .dashboard-top-bar {
    padding: 5px 10px;
  }

  .dashboard-menu-toggle {
    font-size: 16px;
  }

  .dashboard-main-content-wrapper.shifted {
    margin-left: 0;
  }

  .dashboard-sidebar {
    width: 250px;
    left: -300px;
  }

  .dashboard-sidebar.open {
    left: 0;
  }

  .dashboard-cash-collection span {
    display: block;
  }
}

