
.store-container {
    display: flex;
    height: 100vh;
    background-color: #f0f4f7;
    font-family: 'Arial', sans-serif;
    overflow: hidden; 
}

.store-sidebar {
    width: 250px;
    background-color: #2c3e50;
    color: white;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
}

.store-sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.store-sidebar li {
    padding: 15px 20px;
    margin: 10px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 4px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
}

.store-sidebar li.active, 
.store-sidebar li:hover {
    background-color: #1abc9c;
    color: white;
}

.store-content {
    margin-left: 250px;
        padding: 40px;
    flex-grow: 1;
    background-color: white;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.05);
    overflow-y: auto;
}

h2 {
    font-size: 28px;
    color: #34495e;
    margin-bottom: 20px;
    font-weight: 600;
}

.store-content > div {
    margin-top: 20px;
    padding: 20px;
    background-color: #ecf0f1;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
