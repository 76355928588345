/* General container styling */
.new-bill-container {
  font-family: Arial, sans-serif;
  margin: 10px;
}

h2 {
  /* margin-bottom: 20px; */
  margin: 5px;
}

/* Search bar styling */
.search-bar {
  position: relative;
}

.search-bar input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.barcode-input{

  width: 100%;
  padding: 10px;
  box-sizing: border-box
}


/* Dropdown Styles */
.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #ddd;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.dropdown table {
  width: 100%;
  border-collapse: collapse;
}

.dropdown th, .dropdown td {
  padding: 8px;
  text-align: left;
}

.dropdown th:nth-child(1), .dropdown td:nth-child(1) {
  width: 10%; /* Adjust width as needed */
}

.dropdown th:nth-child(2), .dropdown td:nth-child(2) {
  width: 25%; /* Adjust width as needed */
}

.dropdown th:nth-child(3), .dropdown td:nth-child(3) {
  width: 10%; /* Adjust width as needed */
}

.dropdown th:nth-child(4), .dropdown td:nth-child(4) {
  width: 10%; /* Adjust width as needed */
}

.dropdown th:nth-child(5), .dropdown td:nth-child(5) {
  width: 5%; /* Adjust width as needed */
}

.dropdown th:nth-child(6), .dropdown td:nth-child(6) {
  width: 10%; /* Adjust width as needed */
}

.dropdown tr {
  cursor: pointer;
}

.dropdown tr:hover {
  background-color: #f0f0f0;
}

/* Table container */
.items-table-container {
  width: 100%;
  max-height: 240px;
  overflow: auto;
  position: relative;
}

/* Table styling */
.items-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; /* Ensures the width is fixed */
}

.items-table thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.items-table th, .items-table td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
}

/* Customize widths of specific columns using nth-child */
.items-table th:nth-child(1), /* Item Code */
.items-table td:nth-child(1) {
  width: 8%; /* Adjust as needed */
}

.items-table th:nth-child(2), /* Item Name */
.items-table td:nth-child(2) {
  width: 20%;
}

.items-table th:nth-child(3), /* Color */
.items-table td:nth-child(3) {
  width: 10%;
}

.items-table th:nth-child(4), /* Size */
.items-table td:nth-child(4) {
  width: 4%;
}

.items-table th:nth-child(5), /* Price */
.items-table td:nth-child(5) {
  width: 5%;
}

.items-table th:nth-child(6), /* Quantity */
.items-table td:nth-child(6) {
  width: 5%;
}

.items-table th:nth-child(7), /* Amount */
.items-table td:nth-child(7) {
  width: 8%;
}

.items-table th:nth-child(8), /* Actions */
.items-table td:nth-child(8) {
  width: 5%;
}

.items-table th {
  background-color: #707070;
  color: white;
}

.items-table input {
  width: 90%; /* Allow input to take full width */
}

.items-table tr:nth-child(even) {
  background-color: #cbcbcb;
}

.items-table button {
  padding: 3px;
  border-radius: 10px;
  background-color: red;
  border: none;
}


/* Summary section */
.summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  position: relative;
}

/* Button styling */
.custom-btn button {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-btn button:hover {
  background-color: #0056b3;
}

/* Center the item summary */
.item-summary {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

/* Payment section - Right alignment */
.payment-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

/* Optional: Improve submit button styling */
#submit-btn {
  padding: 5px 30px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button button:hover {
  background-color: #218838;
}


/* Customer info section */
.customer-info {
  margin: 20px 0;
}

.customer-info label {
  display: block;
  margin-bottom: 10px;
}

.customer-info input, .customer-info select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

/* Buttons */
.buttons {
  margin: 20px 0;
}

.buttons button {
  padding: 10px 20px;
  margin-right: 10px;
}

/* Modal styling */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: none; /* Initially hidden */
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-content h3 {
  margin-bottom: 20px;
}

.modal-content button {
  padding: 10px 20px;
  margin: 0 5px;
}


.customer-details {
  padding-top: 80px !important; 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  padding: 10px;
  background: #f8f9fa; /* Light background */
  border-radius: 5px;
  white-space: nowrap; /* Prevent text from wrapping */
  
}

.credit-info {
  flex: 1; /* Takes equal space */
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  padding-left: 350px;
}

.school-info {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between label and dropdown */
}

.school-label {
  white-space: nowrap; /* Prevents label from stacking */
}

.customer-details > * {
  flex-shrink: 0; /* Prevents flex items from wrapping */
}


.mode-toggle {
  position: relative;
}

.mode-toggle button {
  padding: 5px 10px;
  font-size: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  left: 10px;
  color: white;
}

.mode-toggle .barcode-mode {
  background-color: green;
}

.mode-toggle .search-mode {
  background-color: blue;
}


.customer-details-box{
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}


/* Styles for the PDF modal */
.pdf-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.pdf-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.pdf-iframe {
  width: 100%;
  height: calc(100vh - 200px); /* Adjust this to fit within your modal */
  border: none;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
}

/* Unique class name for the custom item entry modal */
.custom-item-modal {
  .modal-content {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .modal-header {
    border-bottom: 1px solid #dee2e6;
    background-color: #f8f9fa;
  }

  .modal-title {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  label {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    color: #495057;
  }

  input {
    padding: 8px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 1rem;
  }

  .modal-footer {
    border-top: 1px solid #dee2e6;
    background-color: #f8f9fa;
  }

  .btn-secondary {
    background-color: #6c757d;
    border: none;
  }

  .btn-primary {
    background-color: #007bff;
    border: none;
  }

  .btn {
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 1rem;
  }
}

.billing-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px; /* Adjust padding as needed */
}

.billing-head {
  flex: 1;
  text-align: center;
  margin-left: 500px;
}

.barcode-input {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  height: 50px;
}

.barcode-input label {
  margin-left: auto; /* Ensures the input is aligned to the right */
}


.exchange-close-button{
  margin-top: 25px !important;
  background-color: red !important;
  border-color: red !important;
  color: white !important;
}


