.add-item-stock {
  max-height: 400px;
  height: 100%;
    padding: 20px;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: scroll;
  }
  
  h2 {
    margin-bottom: 20px;
  }
  
  .add-row-button, .submit-button {
    margin: 10px 0;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .add-row-button:hover, .submit-button:hover {
    background-color: #45a049;
  }
  
  .item-stock-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .item-stock-table th, .item-stock-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }


  
  /* Set specific column widths using nth-child */
  .item-stock-table th:nth-child(1),
  .item-stock-table td:nth-child(1) {
    width: 10%; /* Width for 'Item Code' */
  }
  
  .item-stock-table th:nth-child(2),
  .item-stock-table td:nth-child(2) {
    width: 15%; /* Width for 'Item Name' */
  }
  
  .item-stock-table th:nth-child(3),
  .item-stock-table td:nth-child(3) {
    width: 20%; /* Width for 'Item Type' */
  }
  
  .item-stock-table th:nth-child(4),
  .item-stock-table td:nth-child(4) {
    width: 8%; /* Width for 'Item Size' */
  }
  
  .item-stock-table th:nth-child(5),
  .item-stock-table td:nth-child(5) {
    width: 10%; /* Width for 'Item Color' */
  }
  
  .item-stock-table th:nth-child(6),
  .item-stock-table td:nth-child(6) {
    width: 20%; /* Width for 'Item Category' */
  }
  
  .item-stock-table th:nth-child(7),
  .item-stock-table td:nth-child(7) {
    width: 6%; /* Width for 'Price' */
  }
  
  .item-stock-table th:nth-child(8),
  .item-stock-table td:nth-child(8) {
    width: 6%; /* Width for 'Wholesale Price' */
  }
  
  .item-stock-table th:nth-child(9),
  .item-stock-table td:nth-child(9) {
    width: 3%; /* Width for 'Quantity' */
  }
  
  .item-stock-table th:nth-child(10),
  .item-stock-table td:nth-child(10) {
    width: 15%; /* Width for 'Description' */
  }

  .item-stock-table th:nth-child(11),
  .item-stock-table td:nth-child(11) {
    width: 20%; /* Width for Group Id */
  }
  

  .item-stock-table th:nth-child(12),
  .item-stock-table td:nth-child(12) {
    width: 15%; /* Width for Group Id */
  }

  .item-stock-table th {
    background-color: #707070;
    color: white;
  }
  
  .item-stock-table td input, 
  .item-stock-table td select {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .item-stock-table td input[type="number"] {
    -moz-appearance: textfield;
  }
  
  .item-stock-table td input[type="number"]::-webkit-outer-spin-button,
  .item-stock-table td input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .error {
    border: 1px solid red;
  }
  
  .error-message {
    color: red;
    font-size: 0.875em;
    margin-left: 5px;
  }
  
  .remove-button {
    background-color: #f44336; /* Red */
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 3px;
    font-size: 1rem;

  }
  
  .remove-button:hover {
    background-color: #d32f2f; /* Darker red */
  }
  
  
  .actionBtns {
    display: flex;                 /* Align items in a row */
    justify-content: center;       /* Center-align the action buttons and file upload */
    align-items: center;           /* Vertically center the items */
    width: 100%;
  }
  
  .actionBtns-left {
    margin-left: 400px;
    display: flex;                 /* Flexbox container for the Clear, Refresh buttons, and file input */
    gap: 10px;                     /* Space between the items */
    align-items: center;           /* Vertically align the items */
  }
  
  .clear-button, .refresh-button {
    
    padding: 10px 20px;
    background-color: #007bff;     /* Green color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.8rem;

  }
  
  .clear-button:hover, .refresh-button:hover {
    background-color: #45a049;    /* Darker green on hover */
  }
  
  .upload-button {
    padding: 10px;
  }
  
  .download-btn-container {
    margin-left: auto;             /* Push the download button container to the far right */
  }
  
  .download-button {
    background-color: #007BFF;    /* Blue color for the 'Download Format' button */
    color: white;
    padding: 10px 20px;
    border: none;
    font-size: 0.8rem;

    border-radius: 5px;
    cursor: pointer;
    
  }
  
  .download-button:hover {
    background-color: #0056b3;     /* Darker blue on hover */
  }
  
  

  .add-item-stock-btn {
    display: flex;                /* Use flexbox to align the buttons in a row */
    justify-content: center;      /* Center the buttons horizontally */
    align-items: center;          /* Center the buttons vertically */
    gap: 10px;                    /* Add space between the buttons */
    width: 100%;                  /* Ensure the container takes up the full width */
  }
  
  .add-row-button {
    background-color: #007BFF;    /* Blue color for the 'Add Item' button */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    
    
  }
  
  .add-row-button:hover {
    background-color: #0056b3;    /* Darker blue on hover */
  }
  
  .submit-button {
    background-color: #4CAF50;    /* Green color for the 'Submit' button */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #0056b3;    /* Darker green on hover */
  }
  