.order-view-data {
    padding: 20px;
    font-family: 'Roboto', sans-serif;
    background-color: #f4f4f4;
}

.filter-section {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;
}

.filter-section label {
    font-size: 16px;
    color: #333;
}

.filter-section select {
    padding: 8px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.order-view-table {
    max-height: 550px;
    overflow-y: auto;
    overflow-x: auto;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    border-radius: 8px;
    padding: 0 10px;
}
.order-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    background-color: #fff;
    border: 1px solid #ddd;
}

.order-table th, 
.order-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
}

.order-table th {
    background-color: #707070;
    color: white;
    font-weight: bold;
}

.order-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.order-table tr:hover {
    background-color: #f1f1f1;
}

.action-buttons {
    display: flex;
    gap: 10px;
}

.action-buttons .btn {
    flex: 1;
    max-width: 80px; /* Reduced max-width for smaller buttons */
}

.action-buttons .btn:nth-child(1) {
    width: 80px; /* Smaller width for first button */
    height: 30px;
}

.action-buttons .btn:nth-child(2) {
    width: 80px; /* Smaller width for first button */
    height: 30px;
}

.action-buttons .btn:nth-child(3) {
    width: 80px; /* Smaller width for first button */
    height: 30px;
}

.btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 6px 12px; /* Reduced padding for smaller buttons */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px; /* Slightly smaller font size */
    margin: 2px 1px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s, transform 0.3s;
}

.btn:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

.delivered {
    background-color: #28a745;
}

.delivered:hover {
    background-color: #218838;
}

.cancelled {
    background-color: #dc3545;
}

.cancelled:hover {
    background-color: #c82333;
}

.sub-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin-top: 10px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.sub-table th, 
.sub-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
}

.sub-table th {
    background-color: #007bff;
    color: white;
    font-weight: bold;
}

.sub-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.sub-table tr:hover {
    background-color: #f1f1f1;
}

.sub-table td {
    font-size: 14px;
}
