.custom-item-billing-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.custom-item-billing-modal {
  width: 100%;
  margin-top: 5px;
  margin-right: 900px;
  max-width: 600px;
  z-index: 1060;
}

.custom-item-billing-content {
  padding: 0;
  border-radius: 8px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
  background-color: white;
  animation: custom-item-billing-fade-in 0.3s ease;
  max-height: 900px; /* Adjusted to prevent scrolling */
  display: flex;
  flex-direction: column;
}

@keyframes custom-item-billing-fade-in {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

.custom-item-billing-header {
  padding: 10px 20px; /* Reduced padding */
  border-bottom: 1px solid #eaeaea;
  background-color: #f8f9fa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.custom-item-billing-title {
  font-size: 1.1rem; /* Slightly reduced */
  font-weight: 600;
  color: #333;
}

.custom-item-billing-close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  line-height: 1;
  color: #666;
  cursor: pointer;
  padding: 0;
  margin: 0;
  transition: color 0.2s;
}

.custom-item-billing-close-button:hover {
  color: #333;
}

.custom-item-billing-body {
  padding: 15px 20px; /* Reduced padding */
  overflow-y: auto;
  flex: 1;
}

/* Changed from grid to single column layout with compact spacing */
.custom-item-billing-form-row {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2px; /* Reduced margin */
}

.custom-item-billing-form-group {
  width: 100%;
  margin-bottom: 8px; /* Reduced margin */
}

.custom-item-billing-label {
  font-size: 0.85rem;
  color: #555;
  font-weight: 500;
  margin-bottom: 4px; /* Reduced margin */
  display: block;
}

.custom-item-billing-input {
  padding: 8px 12px; /* Reduced padding */
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.9rem;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.2s;
}

.custom-item-billing-input:focus {
  outline: none;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.15);
}

.custom-item-billing-input[readonly] {
  background-color: #f8f9fa;
  color: #666;
  cursor: not-allowed;
}

.custom-item-billing-footer {
  padding: 10px 20px; /* Reduced padding */
  margin-top: -20px;
  border-top: 1px solid #eaeaea;
  background-color: #f8f9fa;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.custom-item-billing-btn {
  padding: 8px 16px; /* Reduced padding */
  font-size: 0.9rem;
  border-radius: 4px;
  font-weight: 500;
  transition: all 0.2s;
  cursor: pointer;
  border: none;
}

.custom-item-billing-btn-secondary {
  background-color: #6c757d;
  color: white;
}

.custom-item-billing-btn-secondary:hover {
  background-color: #5a6268;
}

.custom-item-billing-btn-primary {
  background-color: #0d6efd;
  color: white;
}

.custom-item-billing-btn-primary:hover {
  background-color: #0b5ed7;
}

/* Responsive adjustments */
@media (max-width: 576px) {
  .custom-item-billing-modal {
    max-width: 95%;
    max-height: 85vh; /* Prevent overflow on small screens */
  }
  
  .custom-item-billing-body {
    padding: 12px 15px; /* Further reduced padding on mobile */
  }
}