/* General container styling */
.salary-generator-container {
  padding: 20px;
  max-width: 800px;
  margin: auto;
}

/* Input section styling */
.input-section {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.input-section select {
  padding: 8px;
  font-size: 14px;
}

/* Button styling */
.button-section button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button-section button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

/* Result table styling */
.result-section table {
  width: 100%;
  border-collapse: collapse;
}

.result-section th,
.result-section td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.result-section th {
  background-color: #f2f2f2;
}

/* Modal overlay styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal content styling */
.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

/* Modal header styling */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
}

.modal-header h5 {
  margin: 0;
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

/* Modal footer styling */
.modal-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.modal-footer button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.result-section {
  margin-top: 20px;
}

.result-section p {
  margin-bottom: 10px;
}

.result-section .error-message {
  color: #dc3545;
}

.result-section table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.result-section table th,
.result-section table td {
  padding: 12px;
  border: 1px solid #e0e0e0;
  text-align: center;
}

.result-section table th {
  background-color: #707070;
  font-weight: bold;
}

.result-section table tbody tr:nth-child(even) {
  background-color: #f0f0f0;
}

/* Manually setting padding and width for each column */
.result-section table th:nth-child(1),
.result-section table td:nth-child(1) {
  width: 10%;
}

.result-section table th:nth-child(2),
.result-section table td:nth-child(2) {
  width: 10%;
}

.result-section table th:nth-child(3),
.result-section table td:nth-child(3) {
  width: 10%;
}

.result-section table th:nth-child(4),
.result-section table td:nth-child(4) {
  width: 10%;
}

.result-section table th:nth-child(5),
.result-section table td:nth-child(5) {
  width: 10%;
}

.result-section table th:nth-child(6),
.result-section table td:nth-child(6) {
  width: 10%;
}

.result-section table th:nth-child(7),
.result-section table td:nth-child(7) {
  width: 10%;
}

.result-section table th:nth-child(7),
.result-section table td:nth-child(7) {
  width: 10%;
}

.result-section table th:nth-child(8),
.result-section table td:nth-child(8) {
  width: 10%;
}

.result-section table th:nth-child(9),
.result-section table td:nth-child(9) {
  width: 20%;
}


