.manage-user-wrapper {
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.manage-user-heading {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.manage-user-error-message {
    color: red;
    text-align: center;
    margin-bottom: 15px;
}

.manage-user-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.manage-user-table th,
.manage-user-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
}

.manage-user-table th {
    background-color: #007BFF;
    color: white;
    font-weight: bold;
}

.manage-user-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.manage-user-table tr:hover {
    background-color: #ddd;
}

.manage-user-modify-btn,
.manage-user-delete-btn {
    padding: 8px 15px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.manage-user-modify-btn {
    background-color: #3498db;
    color: white;
    font-size: 14px;
}

.manage-user-delete-btn {
    background-color: #e74c3c;
    color: white;
    font-size: 14px;
}

.manage-user-modify-btn:hover {
    background-color: #2980b9;
}

.manage-user-delete-btn:hover {
    background-color: #c0392b;
}

.manage-user-no-data {
    text-align: center;
    color: #999;
    padding: 15px;
}
