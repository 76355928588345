.filter-container {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f5f5f5;
  max-width: 100%;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.filter-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  margin-bottom: 15px;
}

.filter-group label {
  font-size: 16px;
  margin-right: 10px;
  color: #555;
}

.filter-group input[type="checkbox"] {
  margin-right: 5px;
}

.filter-group input[type="date"],
.filter-group select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 200px;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.search-button {
  padding: 10px 20px;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.search-button:hover {
  background-color: #0056b3;
}

.sales-report-container {
  margin-top: 20px;
}

.checkbox-group{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  margin: 20px 0;
}

.dropdown-group{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 18px;
  margin: 10px 0;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.custom-spinner {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-left-color: #f7f7f7; /* Change color as needed */
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
  margin-right: 8px;
}


