.user-cash-collection-container {
  padding: 16px;
}

.filter-buttons-and-date-picker {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.filter-buttons {
  display: flex;
  gap: 8px;
}

.filter-button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filter-button.active {
  background-color: #2000ac;
}

.filter-button:hover {
  background-color: #003fb4;
}

.custom-date-picker-inline label {
  margin-right: 8px;
}

.user-cash-collection-table {
  overflow-y: auto; /* Enable vertical scrolling */
  max-height: 480px; /* Fixed height for the table container */
  position: relative; /* Positioning context for sticky elements */
}

.user-cash-collection-table table {
  width: 100%;
  border-collapse: collapse;
}

.user-cash-collection-table th,
.user-cash-collection-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.user-cash-collection-table tfoot tr {
  background-color: #f1f1f1; /* Highlighted background for summary section */
  position: sticky;
  bottom: 0; /* Sticky to the bottom of the container */
  z-index: 2; /* Layer above the scrolling table rows */
  font-weight: bold; /* Bold text for summary */
}

.user-cash-collection-table tfoot td {
  text-align: center; /* Center-align the text in the footer cells */
  padding: 10px; /* Add padding for better spacing */
}

.header-with-export {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.export-button {
  background-color: #4CAF50;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 6px;
}

.export-button:hover {
  background-color: #45a049;
}