/* General container styling */
.new-bill-container {
  font-family: Arial, sans-serif;
  margin: 10px;
}

h2 {
  /* margin-bottom: 20px; */
  margin: 5px;
}

/* Search bar styling */
.search-bar {
  position: relative;
}

.search-bar input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.billing-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}

.mode-toggle {
  display: flex;
  align-items: center;
}

.mode-toggle button {
  padding: 8px 12px !important;
  font-size: 15px !important;
  width: 150px !important;
  height: 40px !important;
  border: none !important;
  border-radius: 4px;
  cursor: pointer;
  color: white;
}

.barcode-mode {
  background-color: rgb(3, 114, 53) !important; 
}

.search-mode {
  background-color: #0b9aed !important;
}

.billing-head {
  text-align: center;
  flex-grow: 1;
}

.barcode-input {
  display: flex;
  align-items: center;
}

.barcode-input input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px; 
  font-size: 15px !important;
  width: 260px !important;
  height: 30px !important;
}



.barcode-input{

  width: 100%;
  padding: 10px;
  box-sizing: border-box
}


/* Dropdown Styles */
.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #ddd;
  max-height: 200px;
  overflow-y: auto;
  z-index: 50 !important;
}

.dropdown table {
  width: 100%;
  border-collapse: collapse;
}

.dropdown th
,.dropdown td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
}

.dropdown th
 {
  padding: 8px;
  background-color: #707070;
  color: white;
  text-align: left;
}

.dropdown th:nth-child(1), .dropdown td:nth-child(1) {
  width: 10%; /* Adjust width as needed */
}

.dropdown th:nth-child(2), .dropdown td:nth-child(2) {
  width: 25%; /* Adjust width as needed */
}

.dropdown th:nth-child(3), .dropdown td:nth-child(3) {
  width: 10%; /* Adjust width as needed */
}

.dropdown th:nth-child(4), .dropdown td:nth-child(4) {
  width: 10%; /* Adjust width as needed */
}

.dropdown th:nth-child(5), .dropdown td:nth-child(5) {
  width: 5%; /* Adjust width as needed */
}

.dropdown th:nth-child(6), .dropdown td:nth-child(6) {
  width: 10%; /* Adjust width as needed */
}

.dropdown tr {
  cursor: pointer;
}

.dropdown tr:hover {
  background-color: #f0f0f0;
}

/* Table container */
.items-table-container {
  width: 100%;
  max-height: 240px;
  overflow: auto;
  position: relative;
}

/* Table styling */
.items-table {
  width: 100%;
  border: 1px solid #ddd;
  table-layout: fixed; /* Ensures the width is fixed */
}

.items-table thead {
  position: sticky;
  top: 0;
}

.items-table th, .items-table td {
  padding: 8px;
  text-align: left;
}

/* Customize widths of specific columns using nth-child */
.items-table th:nth-child(1), /* Item Code */
.items-table td:nth-child(1) {
  width: 8%; /* Adjust as needed */
}

.items-table th:nth-child(2), /* Item Name */
.items-table td:nth-child(2) {
  width: 20%;
}

.items-table th:nth-child(3), /* Color */
.items-table td:nth-child(3) {
  width: 10%;
}

.items-table th:nth-child(4), /* Size */
.items-table td:nth-child(4) {
  width: 4%;
}

.items-table th:nth-child(5), /* Discount */
.items-table td:nth-child(5) {
  width: 5%;
}


.items-table th:nth-child(6), /* Price */
.items-table td:nth-child(6) {
  width: 5%;
}

.items-table th:nth-child(7), /* Quantity */
.items-table td:nth-child(7) {
  width: 5%;
}

.items-table th:nth-child(8), /* Amount */
.items-table td:nth-child(8) {
  width: 8%;
}

.items-table th:nth-child(9), /* Actions */
.items-table td:nth-child(9) {
  width: 5%;
}

.items-table th {
  background-color: #707070;
  color: white;
}

.items-table input {
  width: 100%; /* Allow input to take full width */
}

.items-table tr:nth-child(even) {
  background-color: #cbcbcb;
}

.items-table button {
  padding: 3px;
  border-radius: 10px;
  background-color: red;
  border: none;
}


/* Summary section */
.summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  position: relative;
}

/* Button styling */
.custom-btn button {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-btn button:hover {
  background-color: #0056b3;
}

/* Discount Section Styling */
.discount-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 20px;
}

.discount-section label {
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
}

.discount-section input {
  padding: 5px;
  width: 80px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.discount-section input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Center the item summary */
.item-summary {
  position: absolute;
  left: 50%;
  margin-top: 50px;
  transform: translateX(-50%);
  text-align: center;
}

/* Payment section - Right alignment */
.payment-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

/* Optional: Improve submit button styling */
#submit-btn {
  padding: 5px 30px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button button:hover {
  background-color: #218838;
}


/* Customer info section */
.customer-info {
  margin: 20px 0;
  
}

.customer-info label {
  display: block;
  margin-bottom: 10px;
}

.customer-info input, .customer-info select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

/* Buttons */
.buttons {
  margin: 20px 0;
}

.buttons button {
  padding: 10px 20px;
  margin-right: 10px;
}




/* Modal styling */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: none; /* Initially hidden */
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-content h3 {
  margin-bottom: 20px;
}

.modal-content button {
  padding: 10px 20px;
  margin: 0 5px;
}
.customer-details {
  padding-left: 40px !important;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  align-items: center;
  margin-top: -20px;
  justify-content: center;
  position: relative;

}

.customer-details-box {
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.customer-details-box label {
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
}

.customer-details-box input {
  width: 200px;
  height: 20px;
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}



.search-bar-container {
  position: relative;
  z-index: 10;
}

.school-name-input {
  position: relative;
  z-index: 5;
}

.school-select-container {
  text-align: left;
  width: 350px;
  margin-left: 5px;


  position: relative;
}

.school-select-container .select__menu {
  position: absolute;
  top: 100%;
  left: 0;

  max-height: 300px;
  overflow-y: auto;
}

.school-select-container .select__control {
  width: 300px !important;
  height: 50px !important;
}

.school-select-container .select__input {
  width: 300px !important;
}

.school-select-container input {
  width: 300px !important;
}


/* Styles for the PDF modal */
.pdf-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.pdf-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.pdf-iframe {
  width: 100%;
  height: calc(100vh - 200px); /* Adjust this to fit within your modal */
  border: none;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
}



.billing-custom-item-modal {
  .modal-content {
    padding: 0;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    max-height: 90vh;
  }

  .modal-header {
    padding: 15px 20px;
    border-bottom: 1px solid #eaeaea;
    background-color: #f8f9fa;
  }

  .modal-title {
    font-size: 1.2rem;
    font-weight: 600;
    color: #333;
  }

  .modal-body {
    padding: 20px;
    max-height: calc(90vh - 130px);
    overflow-y: auto;
  }

  .form-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    margin-bottom: 10px;
  }

  .form-group {
    margin-bottom: 12px;
  }

  label {
    font-size: 0.85rem;
    color: #555;
    font-weight: 500;
    margin-bottom: 4px;
    display: block;
  }

  input {
    padding: 8px 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 0.9rem;
    width: 100%;
    transition: border-color 0.2s;
  }

  input:focus {
    outline: none;
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  input[readonly] {
    background-color: #f8f9fa;
    color: #666;
  }

  .modal-footer {
    padding: 12px 20px;
    border-top: 1px solid #eaeaea;
    background-color: #f8f9fa;
  }

  .btn {
    padding: 8px 16px;
    font-size: 0.9rem;
    border-radius: 4px;
    font-weight: 500;
    transition: all 0.2s;
  }

  .btn-secondary {
    background-color: #ff0000;
    border: 1px solid #d8d8d8;
    color: 
    #ffffff;
  }

  .btn-secondary:hover {
    background-color: #e5e5e5;
  }

  .btn-primary {
    background-color: #0d6efd;
    border: none;
  }

  .btn-primary:hover {
    background-color: #0b5ed7;
  }
}

.blurred-background {
  filter: blur(5px);
  pointer-events: none; /* Prevent interaction while loading */
}

.loading-overlay {
  position: fixed; /* Cover the entire viewport */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it appears above all other elements */
}


.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 5px solid #fff;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.transaction-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.popup-content {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 24px;
  width: 90%;
  max-width: 600px;
}

.billingtransaction-model {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 16px;
}

.billingtransaction-model th, 
.billingtransaction-model td {
  border: 1px solid #e0e0e0;
  padding: 12px;
  text-align: left;
}

.billingtransaction-model th {
  background-color: #707070;
  color: #ffffff;
  font-weight: 600;
}

.billingtransaction-model input,
.billingtransaction-model select {
  width: 100%;
  height: 100%;
  padding: 12px;
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.2s;
  box-sizing: border-box;
  font-size: 16px;
}

.billingtransaction-model input:focus,
.billingtransaction-model select:focus {
  border-color: #4a90e2;
}

.billingtransaction-model td {
  padding: 0;
}

.billingtransaction-model td > * {
  width: 100%;
  height: 100%;
}




.transaction-model-remove-btn {
   background-color: #f42222 !important;
   color: white !important;
   width: 70% !important;
   border: none !important;
   padding: 6px 12px !important;
   border-radius: 4px !important;
   cursor: pointer !important;
   display: flex !important;
   justify-content: center !important;
   align-items: center !important;
   margin: 0 auto !important;
   text-align: center !important;
   font-weight: bold !important;
   transition: background-color 0.3s ease !important;
}
.transaction-model-remove-btn:hover {
  background-color: red !important;
}


.popup-actions-submit-btn{
  color: #ffffff;
  background-color: #218838;
}


.popup-actions-cancel-btn{
  color: #ffffff;
  background-color: red;
}


.error-field {
  border: 1px solid red;
}

.error-text {
  color: red;
  font-size: 0.8rem;
  margin-top: 4px;
  display: block;
}

.popup-actions {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.error-message {
  color: red;
  margin-bottom: 10px;
  font-weight: bold;
}

/* Style for the hold-bill-section */
.hold-bill-section {
  display: flex;
  justify-content: space-between; /* Space between the buttons */
  align-items: center;
  gap: 20px; /* Space between Hold Bill button and Continue Bill container */
  padding: 20px;
  width: 100%; /* Ensures the section takes the full width of its container */
}

/* Style for the hold-bill-section */
.hold-bill-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 20px;
  width: 100%;
}


/* Style for the hold-bill-section */
.hold-bill-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 20px;
  width: 100%;
}

/* Style for the Hold Bill button */
.hold-bill-button {
  font-size: 16px;
  padding: 10px 20px;
  background-color: #f4830b;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.hold-bill-button:hover {
  background-color: #45a049;
}

.hold-bill-button:focus {
  outline: none;
}

/* Container for Continue Bill buttons */
.continue-bills-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

/* Wrapper for each Continue Bill button and discard icon */
.continue-bill-wrapper {
  position: relative;
}

/* Style for Continue Bill buttons */
.continue-bill-button {
  font-size: 14px;
  padding: 8px 30px 8px 18px; /* Adjusted padding to make space for the cross */
  background-color: #f0ad4e;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-flex;
  align-items: center;
}

/* Ensure the text doesn't overlap the icon */
.continue-bill-button span {
  margin-left: 8px; /* Space between the text and the cross */
}

.continue-bill-button:hover {
  background-color: #ec971f;
}

.continue-bill-button:focus {
  outline: none;
}

/* Style for the discard (X) icon */
.discard-bill {
  position: absolute;
  top: -2px; /* Fine-tune position */
  right: -2px; /* Fine-tune position */
  font-size: 18px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.discard-bill:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.intercompany-customer-details {

  display: flex;
  flex-direction: column;
  font-size: 18px;
  margin-top: 40px;
  align-items: center;
  justify-content: center;
}

.intercompany-customer-details-box {
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.intercompany-customer-details-box label {
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
}

.intercompany-customer-details-box select,
.intercompany-customer-details-box input {
  width: 250px;
  height: 30px;
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Ensuring React Select (dropdown) is the same size */
.inter-company-school-name-input .Select__control {
  width: 250px !important;
  height: 30px !important;
  z-index: 9999;
}
