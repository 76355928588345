.dashboard-container {
  padding: 20px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  gap: 15px; /* Reduced the gap between rows */
}

.card-row {
  display: flex;
  justify-content: center;
  gap: 10px; /* Reduced the gap between cards */
  flex-wrap: wrap;
}

.card-container {
  flex: 1;
  max-width: 220px; /* Reduced width for smaller gap */
  max-height: 150px;
  background-color: #42a5f5;
  border-radius: 12px;
  padding: 20px;
  transition: transform 0.2s, box-shadow 0.2s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.card-body {
  text-align: center;
}

.icon {
  font-size: 3rem;
  margin-bottom: 10px;
}

.card-title {
  font-size: 1.25rem;
  font-weight: 500;
}

.card-value {
  font-size: 1.75rem;
  font-weight: bold;
}

@media (max-width: 767px) {
  .card-container {
    max-width: 100%; /* Full width for smaller screens */
    height: 180px;
  }
}
