/* General styling */
.salary-register-body {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  position: relative;
  z-index: 1;
}

.salary-register h2 {
  text-align: center;
  margin-bottom: 20px;
}

/* Table styling */
.salary-register-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  position: relative;
  z-index: 0;
}

.salary-register-table th,
.salary-register-table td {
  border: 1px solid #ddd;
  text-align: center;
  padding: 10px;
}

/* Inputs inside table */
.salary-register-table td input[type="text"],
.salary-register-table td input[type="number"],
.salary-register-table td input[type="date"],
.salary-register-table td select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Delete button styling */
.salary-register-delete-button {
  background-color: red !important;
  color: white !important;
  border: none !important;
  padding: 8px 16px !important;
  border-radius: 25px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease-in-out, transform 0.2s !important;
}

.salary-register-delete-button:hover {
  background-color: darkred !important;
  transform: scale(1.05) !important;
}

.salary-register-delete-button:active {
  background-color: maroon !important;
  transform: scale(0.95) !important;
}

/* Action buttons */
.salary-register-btn button {
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: 2px solid #007bff;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.salary-register-btn button:hover {
  background-color: #0056b3;
}

/* Error row styling */
.salary-register-error-row {
  background-color: #f8d7da;
}

/* Popup styling */
.salary-register-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.salary-register-popup-content {
  background-color: #fff;
  padding: 50px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
  max-width: 80%;
  text-align: center;
}

.salary-register-popup-close {
  margin-top: 20px;
  font-size: 24px;
  cursor: pointer;
  color: black;
  background-color: transparent;
  border: 2px solid black;
  padding: 10px 20px;
  border-radius: 4px;
}

.salary-register-popup-close:hover {
  color: red;
  border-color: red;
}

/* Modal Styling */
.salary-register-modal {
  display: flex;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}

.salary-register-modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.salary-register-modal-close {
  float: right;
  font-size: 28px;
  cursor: pointer;
}

.salary-register-modal-close:hover {
  color: red;
}
