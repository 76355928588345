/* login.css */

html,
body {
    height: 100%;
    margin: 0;
    font-family: Arial, sans-serif;
    overflow: hidden;
    /* Prevent scrolling */
    display: flex;
    flex-direction: column;
}

.loginContainer {
    height: 100vh;
    background-image: url('../Icon/bg1.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.site-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(245, 244, 244, 0.7);
    color: white;
    height: 100px;
}

.logo {
    height: 90px;
    margin-right: 15px;
}

.company-name {
    font-size: 24px;
    margin: 0;
}

.login-background {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    /* Allow this section to grow and take available space */
    padding: 20px;
}

.login-container {
    background-image: url('../Icon/background.jpg');
    background-size: cover;
    background-position: center;
    height: 350px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    width: 300px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.9);
}

.login-container h2 {
    margin-bottom: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.login-container label {
    display: block;
    text-align: left;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
}

.login-container input {
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.login-container button {
    width: 100%;
    margin-top: 8px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #000000;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}

.login-container button:hover {
    background-color: #293d53;
}

.login-container .forgot-password {
    display: block;
    margin-top: 10px;
    color: #000000;
    text-decoration: none;
    font-weight: bold;
}

.login-container .forgot-password:hover {
    color: #293d53;
}

.site-footer {
    /* position: fixed;
    width: 100vw;
    bottom: 0;     */
    background-color: rgba(255, 255, 255, 0.9);
    padding: 20px;
    text-align: center;
    background-color: rgba(245, 244, 244, 0.7);
    border-top: 1px solid #e7e7e7;
    flex-shrink: 0;
    /* Ensure footer stays at the bottom */
}

.site-footer p {
    margin: 5px 0;
    color: #333;
}