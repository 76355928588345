/* General container styling */
.inventory-qty-container {
  max-width: 1200px;
  margin: 20px auto;
  padding: 10px;
  margin-top: -15px;
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* Four-column grid for sections */
.inventory-qty-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

/* Individual section styling */
.inventory-qty-section {
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  height: auto;
}

.inventory-qty-section h2 {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}

/* Scoped button styles */
.inventory-qty-section button {
  display: block;
  width: 100%;
  margin: 5px 0;
  padding: 8px;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.inventory-qty-section button:hover {
  background-color: #0056b3;
}

/* Scoped input and select styles */
.inventory-qty-section input,
.inventory-qty-section select {
  display: block;
  width: 100%;
  margin: 5px 0;
  padding: 8px;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: all 0.3s ease;
}

/* File input specific styling */
.inventory-qty-drag-area {
  border: 1px dashed #007bff;
  padding: 10px;
  background-color: #e9f5ff;
  border-radius: 6px;
  text-align: center;
  color: #007bff;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.inventory-qty-drag-area:hover {
  background-color: #d0ebff;
}

/* Update the table styling */
.inventory-qty-table-container {
  overflow-y: auto;
  margin-top: -10px;
  width: 100%;
  max-height: 240px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.inventory-qty-styled-table {
  width: 100%;
  border-collapse: collapse;
}

.inventory-qty-styled-table th,
.inventory-qty-styled-table td {
  text-align: center;
  border: 1px solid #ddd; /* Ensure all cells have borders */
  padding: 3px; /* Reduce padding to decrease height */
  font-size: 12px;
  height: 25px; /* Explicitly set height for all rows */
}

.inventory-qty-styled-table th {
  background-color: #707070;
  color: white;
  position: sticky;
  top: 0;
  z-index: 1;
  border: 1px solid #555; /* Add visible border to table headings */
}

.inventory-qty-styled-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Adjust column widths */
.inventory-qty-styled-table th:nth-child(1),
.inventory-qty-styled-table td:nth-child(1) {
  width: 80px; /* Reduced School Code width */
}

.inventory-qty-styled-table th:nth-child(2),
.inventory-qty-styled-table td:nth-child(2) {
  width: 80px; /* Reduced Item Code width */
}

.inventory-qty-styled-table th:nth-child(3),
.inventory-qty-styled-table td:nth-child(3) {
  width: 60px; /* Reduced Size width */
}

.inventory-qty-styled-table th:nth-child(4),
.inventory-qty-styled-table td:nth-child(4) {
  width: 60px; /* Reduced Color width */
}

.inventory-qty-styled-table th:nth-child(5),
.inventory-qty-styled-table td:nth-child(5) {
  width: 90px; /* Reduced Current Quantity width */
}

.inventory-qty-styled-table th:nth-child(6),
.inventory-qty-styled-table td:nth-child(6) {
  width: 60px; /* Reduced Quantity width */
}

.inventory-qty-styled-table th:nth-child(7),
.inventory-qty-styled-table td:nth-child(7) {
  width: 60px; /* Reduced Store ID width */
}

.inventory-qty-styled-table th:nth-child(8),
.inventory-qty-styled-table td:nth-child(8) {
  width: 60px; /* Reduced Actions width */
}

/* Adjust input field within the table */
.inventory-qty-styled-table input[type="number"] {
  width: 95% !important; /* Make input fields smaller */
  height: 100% !important; /* Reduce height of input fields */
  padding: 2px 4px; /* Smaller padding for inputs */
  
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 3px;
  text-align: center;
}

/* Adjust button size in the table */
.inventory-qty-styled-table button {
  padding: 2px 8px; /* Reduce button padding */
  font-size: 11px; /* Smaller font size for buttons */
  height: 22px; /* Explicit height for buttons */
}

.inventory-qty-status-box {
  background-color: #ffffff;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
  font-size: 12px;
  color: #333;
  text-align: left;
  max-height: 150px;
  overflow-y: auto;
  word-wrap: break-word;
  white-space: pre-wrap;
}

/* Responsive grid for smaller screens */
@media (max-width: 768px) {
  .inventory-qty-grid {
    grid-template-columns: 1fr;
  }

  .inventory-qty-section {
    padding: 8px;
  }
}

.inventory-qty-toast-notification {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #28a745;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* View history button styling */
.inventory-qty-view-history-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 8px 12px;
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.inventory-qty-view-history-button:hover {
  background-color: #5a6268;
}

/* File info display styling */
.inventory-qty-file-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}